import http from "../service/http";

const apiEndpoints = {
  customers: "/customers",
  cards: "/cards",
  payments: "/payments",
  refunds: "/refunds",
  login: "/login",
  adminLogin: "/login/admin",
  admin: "/admin",
  settings: "/settings",
  invoices: "/invoices",
  invoiceItems: "/invoiceitems",
  errors: "/errors",
  emails: "/emails",
};

export const createAdminUser = async () => await http.post(apiEndpoints.admin);
export const getAdminUser = () => http.get(apiEndpoints.admin);
export const updateAdminUser = (payload) =>
  http.put(`${apiEndpoints.admin}/${payload.id}`, payload.data);

export const createCustomer = (payload) =>
  http.post(apiEndpoints.customers, payload);
export const getAllCustomers = () => http.get(apiEndpoints.customers);
export const getCustomerDetails = (id) =>
  http.get(`${apiEndpoints.customers}/${id}`);

export const deleteCustomer = (id) =>
  http.delete(`${apiEndpoints.customers}/${id}`);

export const createPaymentMethod = (payload) =>
  http.post(apiEndpoints.cards, payload);

export const deletePaymentMethod = (query) =>
  http.delete(
    `${apiEndpoints.cards}?customerId=${query.customerId}&cardId=${query.cardId}`
  );

export const createPayment = (payload) =>
  http.post(apiEndpoints.payments, payload);

export const createRefund = (payload) =>
  http.post(apiEndpoints.refunds, payload);

export const updateCustomer = (payload) =>
  http.put(`${apiEndpoints.customers}/${payload.id}`, payload.data);

export const userLogin = (payload) => http.post(apiEndpoints.login, payload);

export const adminLogin = (payload) =>
  http.post(apiEndpoints.adminLogin, payload);

export const createSettings = (payload) =>
  http.post(apiEndpoints.settings, payload);

export const getSettings = () => http.get(apiEndpoints.settings);
export const updateSettings = (payload) =>
  http.put(`${apiEndpoints.settings}/${payload.id}`, payload.data);

export const getInvoices = (id) => http.get(`${apiEndpoints.invoices}/${id}`);
export const createInvoice = (payload) =>
  http.post(apiEndpoints.invoices, payload);

export const cancelInvoice = (id) =>
  http.put(`${apiEndpoints.invoices}/void/${id}`);

export const updateInvoice = (payload) =>
  http.put(`${apiEndpoints.invoices}/${payload.id}`, payload.data);

export const createInvoiceItem = (payload) =>
  http.post(apiEndpoints.invoiceItems, payload);

export const updateInvoiceItem = (payload) =>
  http.put(`${apiEndpoints.invoiceItems}/${payload.id}`, payload.data);

export const deleteInvoiceItem = (id) =>
  http.delete(`${apiEndpoints.invoices}/${id}`);

export const getApplicationErrors = () => http.get(apiEndpoints.errors);
export const sendReminderEmail = (payload) =>
  http.post(`${apiEndpoints.invoices}/reminder`, payload);

export const getClinicInvoices = () =>
  http.get(`${apiEndpoints.invoices}/clinic`);

export const sendTransactionReceipt = (payload) =>
  http.post(`${apiEndpoints.emails}/transaction-receipt`, payload);

export const registerPatient = (payload) =>
  http.post(`${apiEndpoints.customers}/patient-registration`, payload);
