import React, { useState } from "react";
import Joi from "joi-browser";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  ArrowLeft,
  TrashFill,
  CreditCardFill,
  Receipt,
} from "react-bootstrap-icons";
import {
  Container,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import IconButton from "../../components/Button";
import AddCard from "../../components/Modals/AddCard";
import RemoveCardModal from "../../components/Modals/RemoveCard";
import RefundModal from "../../components/Modals/RefundModal";
import PaymentModal from "../../components/Modals/PaymentModal";
import SavedCards from "./components/SavedCards";
import TransactionHistory from "./components/TransactionHistory";
import StripeElements from "../../components/StripeElements";
import { keys } from "../../query/keys";
import {
  createPaymentMethod,
  deletePaymentMethod,
  deleteCustomer,
  getCustomerDetails,
  createPayment,
  createRefund,
  updateCustomer,
} from "../../query/queryFunctions";
import CustomerDeleteModal from "../../components/Modals/DeleteCustomer";
import RefundDetailsModal from "../../components/Modals/RefundDetails";
import ChargeModal from "../../components/Modals/ChargeModal";
import InvoiceModal from "../../components/Modals/InvoiceModal";
import InvoiceListing from "./components/InvoiceListing";

const paymentValidationSchema = {
  amount: Joi.number().min(1).positive().required().label("Amount"),
  note: Joi.string().max(250).required().label("Note"),
};

const refundValidationSchema = {
  amount: Joi.number().min(0.5).positive().required().label("Amount"),
  reason: Joi.string().max(250).required().label("Reason"),
};

const CustomerDetails = () => {
  const params = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [cardId, setCardId] = useState("");
  const [charge, setCharge] = useState({});
  const [chargeErrors, setChargeErrors] = useState({});

  const [refundData, setRefundData] = useState({});
  const [refundDataErrors, setRefundDataErrors] = useState({});
  const [refunds, setRefunds] = useState({});

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState(false);
  const [isRefundDetailsModalOpen, setIsRefundDetailsModalOpen] =
    useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isRemoveCardOpen, setIsRemoveCardOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("1");

  const { isLoading, data: customer } = useQuery(keys.customer(params.id), () =>
    getCustomerDetails(params.id)
  );

  const validatePaymentFields = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(charge, paymentValidationSchema, options);
    if (!error) {
      return null;
    }
    const errors = {};
    for (let item of error?.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validatePaymentInputField = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: paymentValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleChangePaymentInput = (key, value) => {
    const data = { ...charge };
    const _errors = { ...chargeErrors };
    const errorMessage = validatePaymentInputField(key, value);
    if (_errors) {
      _errors[key] = errorMessage;
    } else {
      delete _errors[key];
    }

    setChargeErrors(_errors);
    data[key] = value;
    setCharge(data);
  };

  const mutation = useMutation(deleteCustomer, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customers);
      setIsDeleteModalOpen(!isDeleteModalOpen);
      history.push("/customers");
    },
  });

  const { isLoading: deletingCustomer } = mutation;

  const addCardMutation = useMutation(createPaymentMethod, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customer(params.id));
      setIsCardModalOpen(!isCardModalOpen);
    },
  });

  const { isLoading: addingCard, mutate } = addCardMutation;

  const removeCardMutation = useMutation(deletePaymentMethod, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customers);
      queryClient.invalidateQueries(keys.customer(params.id));
      toggleRemoveCardModal();
    },
  });

  const { isLoading: removingCard } = removeCardMutation;

  const handleRemoveCard = () => {
    const queryParams = {
      cardId,
      customerId: params.id,
    };
    removeCardMutation.mutate(queryParams);
  };

  const createPaymentMutation = useMutation(createPayment, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customer(params.id));
      setChargeErrors({});
      setCharge({});
      setCardId("");
      setIsPaymentModalOpen(false);
      setIsChargeModalOpen(false);
    },
  });

  const handleCreatePayment = () => {
    const invalidObject = validatePaymentFields();
    if (invalidObject) {
      setChargeErrors(invalidObject);
      return;
    } else {
      setChargeErrors({});
      const payload = {
        amount:
          Math.round((parseFloat(charge?.amount) + Number.EPSILON) * 100) / 100,
        customer: params?.id,
        receipt_email: customer?.customer?.email,
        payment_method: cardId,
        description: charge?.note,
      };
      createPaymentMutation.mutate(payload);
    }
  };

  const handleCreatePaymentWithoutSavingCard = (payment_method) => {
    const invalidObject = validatePaymentFields();
    if (invalidObject) {
      setChargeErrors(invalidObject);
      return;
    } else {
      setChargeErrors({});
      const payload = {
        amount:
          Math.round((parseFloat(charge?.amount) + Number.EPSILON) * 100) / 100,
        customer: params?.id,
        receipt_email: customer?.customer?.email,
        payment_method: payment_method,
        description: charge?.note,
      };
      createPaymentMutation.mutate(payload);
    }
  };

  const validateRefundFields = () => {
    const options = { abortEarly: false };
    const data = { amount: refundData?.amount, reason: refundData?.reason };
    const { error } = Joi.validate(data, refundValidationSchema, options);
    if (!error) {
      return null;
    }
    const errors = {};
    for (let item of error?.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateRefundInputField = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: refundValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleChangeRefundData = (key, value) => {
    let data = { ...refundData };
    let _errors = { ...refundDataErrors };
    const errorMessage = validateRefundInputField(key, value);
    if (errorMessage) {
      _errors[key] = errorMessage;
    } else {
      delete _errors[key];
    }
    setRefundDataErrors(_errors);
    data[key] = value;
    setRefundData(data);
  };

  const refundMutation = useMutation(createRefund, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customers);
      queryClient.invalidateQueries(keys.customer(params.id));
      toggleRefundModal();
      setRefundDataErrors({});
    },
  });

  const handleRefundPayment = () => {
    const invalidObject = validateRefundFields();
    if (invalidObject) {
      setRefundDataErrors(invalidObject);
      return;
    }
    const payload = {
      chargeId: refundData.chargeId,
      amount:
        Math.round((parseFloat(refundData?.amount) + Number.EPSILON) * 100) /
        100,
      reason: refundData.reason,
    };
    refundMutation.mutate(payload);
  };

  const updateCustomerMutation = useMutation(updateCustomer, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customers);
      queryClient.invalidateQueries(keys.customer(params.id));
    },
  });

  const handleUpdateCustomer = (cardId) => {
    const payload = {
      id: params.id,
      data: {
        default_source: cardId,
      },
    };
    updateCustomerMutation.mutate(payload);
  };

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    );
  }

  const toggleChargeModal = () => {
    setIsChargeModalOpen(!isChargeModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleCardModal = () => {
    setIsCardModalOpen(!isCardModalOpen);
    if (!cardId) {
      setCardId(params.id);
    } else {
      setCardId("");
    }
  };

  const toggleRemoveCardModal = () => {
    setIsRemoveCardOpen(!isRemoveCardOpen);
    setCardId("");
  };

  const toggleRefundModal = () => {
    if (refundData || refundDataErrors) {
      setIsRefundModalOpen(!isRefundModalOpen);

      setRefundData({});
      setRefundDataErrors({});
    } else {
      setIsRefundDetailsModalOpen(!isRefundModalOpen);
    }
  };

  const togglePaymentModal = () => {
    setIsPaymentModalOpen(!isPaymentModalOpen);
    setChargeErrors({});
    setCharge({});
  };

  const toggleRefundDetailsModal = () => {
    setIsRefundDetailsModalOpen(!isRefundDetailsModalOpen);
    setRefunds("");
  };

  const toggleInvoiceModal = () => {
    setIsInvoiceModalOpen(!isInvoiceModalOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDeleteCustomer = () => {
    mutation.mutate(params.id);
  };

  return (
    <div className="mt-5 p-4">
      <IconButton
        title="Go Back"
        color="primary"
        size="sm"
        className="mb-2"
        onClick={() => history.push("/customers")}
        icon={<ArrowLeft className="mr-2" />}
      />
      <IconButton
        title="Delete Customer"
        color="danger"
        size="sm"
        className="float-right mb-2"
        icon={<TrashFill className="mr-2" />}
        onClick={toggleDeleteModal}
        disabled={deletingCustomer}
      />
      <Table responsive bordered hover>
        <thead class="thead-dark">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{customer?.customer?.id}</td>
            <td>{customer?.customer?.name}</td>
            <td>{customer?.customer?.email}</td>
            <td>{customer?.customer?.description}</td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-3 mb-3">
        <IconButton
          title="Add New Card"
          color="primary"
          size="sm"
          className="float-right"
          onClick={toggleCardModal}
          icon={<CreditCardFill className="mr-2" />}
        />
        <IconButton
          title="Charge a Card"
          color="primary"
          size="sm"
          className="float-right mr-2"
          onClick={toggleChargeModal}
          icon={<CreditCardFill className="mr-2" />}
        />
        <IconButton
          title="Send Invoice"
          color="info"
          size="sm"
          className="float-right mr-2"
          onClick={toggleInvoiceModal}
          icon={<Receipt className="mr-2" />}
        />
      </div>
      <div className="mt-5">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Saved Cards
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Transaction History
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Invoices
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-4">
          <TabPane tabId="1">
            <SavedCards
              cards={customer?.cards}
              defaultCard={customer?.customer?.default_source}
              setCardId={setCardId}
              togglePaymentModal={togglePaymentModal}
              toggleRemoveCardModal={toggleRemoveCardModal}
              handleUpdateCustomer={handleUpdateCustomer}
              isLoading={updateCustomerMutation.isLoading}
            />
          </TabPane>
          <TabPane tabId="2">
            <TransactionHistory
              customer={customer?.customer?.email}
              toggleRefundModal={toggleRefundModal}
              charges={customer?.charges}
              setRefundData={setRefundData}
              toggleRefundDetailsModal={toggleRefundDetailsModal}
              setRefunds={setRefunds}
            />
          </TabPane>

          <TabPane tabId="3">
            <InvoiceListing customerId={params.id} />
          </TabPane>
        </TabContent>
      </div>

      <StripeElements>
        <AddCard
          isOpen={isCardModalOpen}
          toggle={toggleCardModal}
          addingCard={addingCard}
          mutate={mutate}
          isError={addCardMutation.isError}
          error={addCardMutation.error?.message}
          customerId={params?.id}
        />

        <ChargeModal
          charge={charge}
          errors={chargeErrors}
          isOpen={isChargeModalOpen}
          toggle={toggleChargeModal}
          isError={createPaymentMutation.isError}
          error={createPaymentMutation.error?.message}
          handleSubmit={handleCreatePaymentWithoutSavingCard}
          handleChange={handleChangePaymentInput}
          isLoading={createPaymentMutation.isLoading}
        />
      </StripeElements>

      <RemoveCardModal
        isOpen={isRemoveCardOpen}
        toggle={toggleRemoveCardModal}
        removingCard={removingCard}
        handleRemoveCard={handleRemoveCard}
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        toggle={toggleRefundModal}
        refundData={refundData}
        refundDataErrors={refundDataErrors}
        handleChange={handleChangeRefundData}
        handleSubmit={handleRefundPayment}
        loading={refundMutation.isLoading}
      />
      <PaymentModal
        charge={charge}
        errors={chargeErrors}
        isOpen={isPaymentModalOpen}
        toggle={togglePaymentModal}
        handleSubmit={handleCreatePayment}
        handleChange={handleChangePaymentInput}
        isLoading={createPaymentMutation.isLoading}
      />

      <CustomerDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        handleSubmit={handleDeleteCustomer}
        deletingCustomer={deletingCustomer}
      />

      <RefundDetailsModal
        isOpen={isRefundDetailsModalOpen}
        toggle={toggleRefundDetailsModal}
        refunds={refunds}
      />

      <InvoiceModal
        isOpen={isInvoiceModalOpen}
        toggle={toggleInvoiceModal}
        customer={customer}
      />
    </div>
  );
};

export default CustomerDetails;
